<template>
  <modal :show.sync="showModal" modal-classes="modal-secondary" size="lg">
    <div>
      <h2>
        {{ modelName ? modelName + " - " : "" }}
        {{ $t("WAREHOUSES.CONFIGURATION_RESULTS") }}
      </h2>
      <div>
        <el-table
          :data="configurationResult"
          style="width: 100%"
          type="expand"
          row-key="id"
        >
          <el-table-column prop="line" :label="$t('COMMON.WAREHOUSE')">
            <template v-slot="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="line"
            :label="$t('WAREHOUSES.PRODUCTS_CREATED')"
          >
            <template v-slot="{ row }">
              {{ `${row.results.CREATED}` }}
            </template>
          </el-table-column>
          <el-table-column
            prop="line"
            :label="$t('WAREHOUSES.PRODUCTS_UPDATED')"
          >
            <template v-slot="{ row }">
              {{ `${row.results.UPDATED}` }}
            </template>
          </el-table-column>
          <el-table-column
            prop="line"
            :label="$t('WAREHOUSES.PRODUCTS_DELETED')"
          >
            <template v-slot="{ row }">
              {{ `${row.results.DELETED}` }}
            </template>
          </el-table-column>
          <el-table-column
            prop="line"
            :label="$t('WAREHOUSES.PRODUCTS_ERRORS')"
          >
            <template v-slot="{ row }">
              {{ `${row.results.ERRORS.length}` }}
            </template>
          </el-table-column>

          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.results.ERRORS"
                style="width: 100%"
                type="expand"
                row-key="id"
              >
                <el-table-column prop="id" :label="$t('WAREHOUSES.PRODUCT')">
                  <template v-slot="{ row }">
                    {{ `${row.product.name}` }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" :label="$t('WAREHOUSES.ERROR')">
                  <template v-slot="{ row }">
                    {{ row.message }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="prev, pager, next"
          :total="configurationResult.length"
        ></el-pagination>
      </div>
    </div>
  </modal>
</template>

<script>
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "warehouse-view-warehouse-configured",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  props: ["configurationResult", "modelName"],

  mixins: [requestErrorMixin],

  data() {
    return {
      showModal: true,
    };
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
