export default {
  type: "warehouses",
  name: null,
  id: null,
  applied_at: null,
  is_model: false,
  taxGroups: [],
  allow_negative_inventory: false,
  allow_unregistered_products: false,
  use_warehouse_taxes: false,
  excerpt: "",
  relationshipNames: [
    "organization",
    "allowedLocations",
    "serviceCenter",
    "establishment",
    "cafeteria",
    "modelUsed",
    "usedBy",
    "taxGroups",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
  modelUsed: {
    type: "warehouses",
    id: null,
  },
  allowedLocations: [],
  usedBy: [],
};
