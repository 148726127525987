<template>
  <div>
    <span v-for="(taxGroup, idx) in taxGroups" :key="taxGroup.id">
      <router-link :to="{ name: 'View TaxGroup', params: { id: taxGroup.id } }">
        {{ `${taxGroup.name}` }}
      </router-link>
      {{ taxGroups.length - 1 > idx ? "," : " " }}<br />
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "tax-groups",

  components: {},

  mixins: [formMixin],

  props: {
    taxGroups: {
      type: Array,
      default: () => [],
      description: "taxGroups",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    taxGroups(taxGroups) {},
  },
};
</script>
