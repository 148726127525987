<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${warehouse.name}` }} - {{ $t("COMMON.PRODUCTS") }}
      </span>
    </div>

    <warehouse-product-list
      :warehouse="warehouse"
      :filterWarehouse="warehouse.id"
      :filterOrganization="warehouse.organization.id"
      :preSelectedSupplier="viewSupplier"
    />
  </div>
</template>

<script>
import WarehouseProductList from "@/components/WarehouseProduct/WarehouseProductList.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "warehouse-view-warehouse-products",

  components: {
    WarehouseProductList,
  },

  props: ["warehouse", "viewSupplier"],

  mixins: [requestErrorMixin],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
