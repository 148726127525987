<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-8">
        {{ `${warehouse.name}` }} - {{ $t("COMMON.WAREHOUSE") }}
      </span>
      <div class="col-4 text-right">
        <base-button
          @click="applyConfiguration()"
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
        >
          {{ $t("COMMON.APPLY_CONFIGURATION") }}
        </base-button>
      </div>
    </div>
    <div class="col-12">
      <form ref="profile_form" class="" @submit.prevent="addWarehouse">
        <div class="row">
          <base-input
            :label="`${$t('WAREHOUSES.WAREHOUSE_TO_CONFIGURE')}`"
            :placeholder="$t('WAREHOUSES.WAREHOUSE_TO_CONFIGURE')"
            class="col-11"
          >
            <warehouses-selector
              :allowNone="false"
              :showAll="false"
              :isOnlyModels="false"
              :filterIdsNotIn="usedWarehouses"
              :warehouses="selectedWarehouses.map((item) => item.id)"
              :includes="'modelUsed,organization'"
              @warehousesChanged="
                (warehouses) => {
                  selectedWarehouses = warehouses;
                }
              "
            />
          </base-input>
          <base-button
            type="button"
            class="btn btn-sm btn-primary my-4 col-1"
            native-type="submit"
            :disabled="selectedWarehouses.length == 0"
          >
            {{ $t("COMMON.ADD") }}
          </base-button>
        </div>
      </form>
    </div>

    <warehouse-list-table
      :key="renderKey"
      :customRemove="true"
      :modelUsed="warehouse.id"
      :filterOrganization="warehouse.organization.id"
      @removeActionHandle="detachWarehouse"
    />

    <warehouse-view-warehouse-configured-results
      v-if="showResultsModal"
      :configurationResult="configurationResult"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";
import WarehousesSelector from "@/components/WarehousesSelector.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import WarehouseListTable from "./WarehouseListTable";
import WarehouseViewWarehouseConfiguredResults from "./WarehouseViewWarehouseConfiguredResults.vue";

export default {
  name: "warehouse-view-warehouse-configured",

  components: {
    WarehouseListTable,
    WarehousesSelector,
    WarehouseViewWarehouseConfiguredResults,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  props: ["warehouse"],

  mixins: [requestErrorMixin],

  data() {
    return {
      showResultsModal: false,
      usedWarehouses: this.warehouse.usedBy.map((item) => item.id),
      configurationResult: [],
      selectedWarehouses: [],
      renderKey: 0,
    };
  },

  computed: {},

  methods: {
    async addWarehouse() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        for (const warehouse of this.selectedWarehouses) {
          const warehouseData = cloneDeep(warehouse);
          warehouseData.modelUsed = this.warehouse;
          await this.$store.dispatch("warehouses/update", warehouseData);
        }
        this.selectedWarehouses = [];
        this.$notify({
          type: "success",
          message: this.$t("WAREHOUSES.WAREHOUSE_UPDATED"),
        });
        this.$emit("warehouseUpdated");
        this.renderKey++;
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        swal.close();
      }
    },

    async detachWarehouse(warehouse) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const warehouseData = cloneDeep(warehouse);
        warehouseData.modelUsed = null;
        await this.$store.dispatch("warehouses/update", warehouseData);

        this.selectedWarehouses = [];
        this.$notify({
          type: "success",
          message: this.$t("WAREHOUSES.WAREHOUSE_UPDATED"),
        });

        this.$emit("warehouseUpdated");
        this.renderKey++;
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        swal.close();
      }
    },

    async applyConfiguration() {
      const confirmation = await swal.fire({
        title: this.$t("WAREHOUSES.APPLY_CONFIGURATION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const results = await this.$store.dispatch(
          "warehouses/applyConfiguration",
          this.warehouse.id
        );
        this.configurationResult = results;
        this.showResultsModal = true;

        this.$emit("warehouseUpdated");
        this.renderKey++;
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        swal.close();
      }
    },
  },

  mounted() {},

  watch: {
    warehouse(warehouseData) {
      this.usedWarehouses = this.warehouse.usedBy.map((item) => item.id);
    },
  },
};
</script>
